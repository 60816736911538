<template>
    <div class="v-application--wrap">
      <v-main>
        <transition name="fade-transform" mode="out-in">
          <v-container class="fill-height pa-0 text-center bg-primary white--text" fluid>
            <v-row class="align-center">
              <v-col>
                <p class="text-h4 font-weight-black">{{ $t('global.error_404') }}</p>
                <p>{{ $t('global.error_404_help') }}</p>
                <v-btn large outlined dark to="/"><v-icon left>mdi-home</v-icon>{{ $t('global.back') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </transition>
      </v-main>
    </div>
  </template>
  <script>
  export default {
    mounted() {
      this.$setBaseTheme();
    }
  };
  </script>
  